<template>
  <div class="fluid" id="requestList" v-if="permission.read_perm == 1">
    <div>
      <div class="request-list-div">
        <v-card class="px-5 py-1">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h4 style="font-weight:bold;margin:auto;color:indigo;">
                ARCHIVE MANAGEMENT SYSTEM
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="refresh"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="12">
              <v-toolbar
                class="request-list-toolbar-1"
                flat
                style="height: 60px; border-radius: 10px 10px 0 0"
              >
                <v-toolbar-title style="color:indigo;"
                  >Repository</v-toolbar-title
                >
                <v-divider class="mx-6" inset vertical></v-divider>
                <div style="width: 150px">
                  <v-btn
                    @click="addArchive"
                    elevation="2"
                    tile
                    small
                    color="black"
                    class="white--text"
                    >Tambah data</v-btn
                  >
                </div>
                <div>
                  <div style="width: 120px">
                    <v-select
                      :disabled="selectedData.length === 0"
                      label="Action"
                      style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                      v-model="actionValue"
                      :items="[
                        { id: 0, name: '' },
                        { id: 1, name: 'Edit' },
                        { id: 4, name: 'Delete' },
                        { id: 5, name: 'Hide' },
                        { id: 7, name: 'Unhide' }
                      ]"
                      item-text="name"
                      item-value="id"
                      :item-disabled="disableItem"
                      outlined
                      return-id
                      dense
                      @change="action"
                    ></v-select>
                  </div>
                </div>
                <!-- <div style="width: 150px">
                  <v-btn
                    @click="exportArchive"
                    elevation="2"
                    tile
                    small
                    color="#e0e0e0"
                    class="ma-2 black--text"
                    >Export</v-btn
                  >
                </div> -->
                <div class="request-list-toolbar-div">
                  <div style="width: 120px">
                    <v-select
                      v-model="paramAPI.category_id"
                      :items="getDropdownDocCategory"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      label="Category"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      dense
                      clearable
                      @change="categoryChange"
                    ></v-select>
                  </div>
                  <div style="width: 170px">
                    <v-select
                      v-model="paramAPI.company_id"
                      :items="getDropdownPlant"
                      label="Company"
                      style="
                        margin-left:10px;
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                    ></v-select>
                  </div>
                  <div style="width: 170px">
                    <v-select
                      v-model="paramAPI.dept_id"
                      :items="department"
                      label="Department"
                      style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                    ></v-select>
                  </div>
                  <v-text-field
                    v-model="paramAPI.keyword"
                    label="Cari Dokumen"
                    type="search"
                    outlined
                    dense
                    @keyup.enter="searchEnter"
                    style="position: relative; top: 15px; width: 10px;
                        margin-left: 10px;"
                  ></v-text-field>
                  <div
                    style="width: 100px;display:flex;justify-content:center;align-items:center;"
                  >
                    <v-btn
                      elevation="2"
                      tile
                      small
                      color="black"
                      class="white--text"
                      @click="searchEnter"
                      >Search</v-btn
                    >
                  </div>
                </div>
              </v-toolbar>

              <v-toolbar
                v-if="
                  getUserProfile.level.find(({ id }) => id === '1') !==
                    undefined
                "
                class="request-list-toolbar-1"
                flat
              >
                <v-col
                  cols="12"
                  style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                >
                  <p
                    style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Tampilkan file tersembunyi
                  </p>

                  <v-checkbox
                    true-value="1"
                    false-value=""
                    v-model="paramAPI.isArchive"
                    style=" margin:0 0 0 10px; height:25px; padding:0;"
                  ></v-checkbox>
                </v-col>
              </v-toolbar>

              <v-toolbar
                class="request-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  @keyup.enter="searchEnter"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>

              <v-toolbar
                v-if="
                  getUserProfile.level.find(({ id }) => id === '1') !==
                    undefined
                "
                class="request-list-toolbar-2"
                flat
              >
                <v-col
                  cols="12"
                  style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                >
                  <p
                    style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Tampilkan file tersembunyi
                  </p>

                  <v-checkbox
                    true-value="1"
                    false-value=""
                    v-model="paramAPI.isArchive"
                    style=" margin:0 0 0 10px; height:25px; padding:0;"
                  ></v-checkbox>
                </v-col>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                fixed-header
                height="55vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                show-select
                :single-select="true"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.doc_no`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.doc_no }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.created_by_name`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.created_by_name }}
                  </div>
                </template>
                <template v-slot:[`item.category`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.category !== null ? item.category.name : '' }}
                  </div>
                </template>
                <template v-slot:[`item.plant_name`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.plant !== null ? singkat(item.plant) : '' }}
                  </div>
                </template>
                <template v-slot:[`item.pic_department_name`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{
                      item.pic_department_name === 'HRD'
                        ? 'HCGS'
                        : item.pic_department_name
                    }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    <!-- <v-btn @click.stop="viewPDF(item)" text icon color="brown">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn> -->
                    <v-btn
                      @click.stop="downloadPdf(item)"
                      text
                      icon
                      color="green"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>

              <div class="pr-items-add-new-line">
                <ArchiveAdd
                  :param="{
                    act: 'add',
                    type: 'repo'
                  }"
                  @loadPage="getDataFromApi"
                  :permission="permission"
                />
              </div>
              <div class="pr-items-add-new-line">
                <ArchiveAdd
                  :param="{
                    act: 'update',
                    type: 'repo',
                    items: itemEdit
                  }"
                  @loadPage="getDataFromApi"
                  :permission="permission"
                />
              </div>
              <div class="pr-items-add-new-line">
                <DetailDocument
                  :result="detail"
                  @close="clear"
                  :permission="permission"
                />
              </div>
              <div class="pr-items-add-new-line">
                <ArchiveImport
                  @loadPage="getDataFromApi"
                  :permission="permission"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <div class="text-center">
      <v-dialog v-model="dialog" width="90%" @click:outside="closePdf">
        <v-card style="position:relative; width:100%;height:90vh;">
          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                @click.prevent="closePdf"
                v-bind="attrs"
                v-on="on"
                text
                icon
                color="white"
                style="position:fixed; top:10px; right:10px; z-index:3; filter:drop-shadow(1px 1px 1px white);"
              >
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
          <div
            id="loader"
            class="d-flex justify-center align-center"
            style="position:absolute;top:0; left:0;width:100%; height:100%;"
          >
            <v-progress-circular
              v-if="isPdfOpen && selectedPdf !== ''"
              :rotate="-90"
              :size="150"
              :width="15"
              :value="value"
              color="black"
            >
              {{ value / 10 }}%
            </v-progress-circular>
          </div>
          <div v-if="showPdf" style="height:100%;padding: 5px 0;">
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  @click.prevent="closePdf"
                  v-bind="attrs"
                  v-on="on"
                  text
                  icon
                  color="white"
                  style="position:fixed; top:10px; right:10px; z-index:3;"
                >
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
            <vue-pdf-app
              v-if="selectedPdf !== ''"
              @pages-rendered="pagesRendered"
              @open="openHandler"
              :config="config"
              :pdf="
                `${download}esanqua/archive_document/sop/${selectedPdf}?access_type=view`
              "
            >
              <template #toolbar-sidebar-prepend="{ toggleTheme }">
                <button @click="toggleTheme = null" type="button">
                  Toggle theme
                </button>
              </template>
            </vue-pdf-app>

            <div
              v-else
              class="d-flex justify-center align-center"
              style="height:100%; width:100%; margin-left:auto; position:relative; z-index:1;background:#eaeaea;"
            >
              <h3 style="margin:0;">File Not Found</h3>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
// import pdf from 'vue-pdf'
import 'vue-pdf-app/dist/icons/main.css'
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  components: {
    ArchiveAdd: () =>
      import(
        '../../../components/archive-management/archive-manage/FormDocDept'
      ),
    DetailDocument: () =>
      import(
        '../../../components/archive-management/archive-manage/DocDeptDetail'
      ),
    ArchiveImport: () =>
      import('../../../components/archive-management/archive-manage/Import')
  },
  data: () => ({
    dialog: false,
    hr: buildType.apiURL('hr'),
    eSanqua: buildType.apiURL('esanqua'),
    download: buildType.apiURL('download'),
    hrsApi: buildType.apiURL('hrs'),
    build: process.env.VUE_APP_BUILD_TYPE,
    isAdmin: false,
    menu_key: 40,
    permission: {},
    application: {
      id: 14,
      name: 'eArchive'
    },
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'name',
      sortType: 'asc',
      company_id: null,
      dept_id: '',
      itemsPerPage: 10,
      doc_type: 4,
      isArchive: '',
      category_id: ''
    },
    totalData: 0,

    // options: {
    //   page: 1,
    //   itemsPerPage: 10,
    //   sortDesc: []
    // },
    options: {},
    actionValue: 0,
    headers: [
      // {
      //   text: 'No. Arsip',
      //   value: 'archive_doc_no',
      //   align: 'left',
      //   sortable: true,
      //   width: '10%'
      // },
      {
        text: 'No. Dokumen',
        value: 'doc_no',
        align: 'left',
        sortable: true,
        width: '15%'
      },
      {
        text: 'Nama Dokumen',
        value: 'name',
        align: 'left',
        sortable: true,
        width: '20%'
      },
      {
        text: 'Kategori',
        value: 'category.name',
        align: 'left',
        sortable: false,
        width: '15%'
      },
      {
        text: 'Plant',
        value: 'plant_name',
        align: 'left',
        sortable: false,
        width: '5%'
      },
      {
        text: 'Department',
        value: 'pic_department_name',
        align: 'left',
        sortable: true,
        width: '10%'
      },
      {
        text: 'Tgl Berlaku',
        value: 'doc_date',
        align: 'left',
        sortable: true,
        width: '10%'
      },
      {
        text: 'Dibuat Oleh',
        value: 'created_by_name',
        align: 'left',
        sortable: true,
        width: '10%'
      },
      {
        text: 'Penerbit',
        value: 'published_by',
        align: 'left',
        sortable: true,
        width: '12%'
      },
      {
        text: '',
        value: 'action',
        align: 'left',
        sortable: false,
        width: '5%'
      }
    ],
    company: [],
    department: [],
    result: [],
    selectedData: [],
    loading: false,
    itemEdit: null,
    detail: null,
    isPdfOpen: false,
    selectedPdf: '',
    showPdf: false,
    info: [],
    config: {
      // toolbar: false,
      secondaryToolbar: false,
      toolbar: {
        toolbarViewerRight: false
        // toolbarViewerLeft: false
      }
      // sidebar: false
    },
    value: 0,
    counter: 0,
    timer: 100,
    tabs: [
      {
        tab_name: 'Repository',
        path: '/archive-management/repository'
      }
    ]
  }),
  async mounted() {
    // admin all document department / admin earchive umum blm ada
    // a = approver_id | b = admin_id
    // const a = this.build === 'development' ? '19' : '20'
    const b = this.build === 'development' ? '28' : '28'
    var arr = this.getUserProfile.level
    this.setTabs(this.tabs)
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            if (arr[i].id === '1' || arr[i].id === b) {
              this.isAdmin = true
            } else {
              this.isAdmin = false
            }
            this.paramAPI.company_id = Number(
              this.getUserProfile.employee.company.plant_id
            )
            this.paramAPI.dept_id = Number(
              this.getUserProfile.employee.department.id
            )
            this.permission = result.data
            this.setPermission(result.data)
            this.initDropdown(arr)
            // this.initDataTable()
            this.getDataFromApi()
          })
          .catch(error => {
            if (error) {
              this.isAdmin = false
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = false
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getDropdownPlant',
      'getDropdownDocCategory'
    ])
  },
  watch: {
    'paramAPI.isArchive'() {
      this.getDataFromApi()
    },
    'paramAPI.company_id'() {
      if (this.paramAPI.company_id !== null) {
        this.dropdownDepartment()
      }
    },
    // 'paramAPI.sortType'() {
    //   this.getDataFromApi()
    // },

    options: {
      handler() {
        console.log(this.options)
        if (
          this.options.sortBy.length === 1 &&
          this.options.sortDesc.length === 1
        ) {
          this.paramAPI.sortType = this.options.sortDesc[0] ? 'desc' : 'asc'
          this.paramAPI.sortBy = this.options.sortBy[0]
        } else {
          this.paramAPI.sortType = ''
          this.paramAPI.sortBy = ''
        }
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['setPermission', 'setTabs']),
    ...mapActions([
      'dropdownPlant',
      'dropdownUnit',
      'dropdownGlobalUnit',
      'dropdownDocStatus',
      'dropdownDocCategory',
      'menu_access'
    ]),
    refresh() {
      this.getDataFromApi()
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    action(event) {
      switch (event) {
        case 1:
          // if (this.permission.update_perm === 1) {
          if (
            this.getUserProfile.employee.name ===
            this.selectedData[0].created_by_name
          ) {
            var app = this.getUserProfile.level
            this.initDropdown(app)
            this.itemEdit = this.selectedData[0]
            setTimeout(() => {
              document.getElementById('archiveEdit').click()
            }, 500)
          } else {
            this.showMsgDialog(
              'warning',
              'Maaf, Hanya pembuatnya yang dapat mengedit dokumen ini.',
              false
            )
            setTimeout(() => {
              this.actionValue = 0
              this.itemEdit = null
              this.selectedData = []
            }, 200)
          }
          // }
          break
        case 4:
          // if (this.permission.delete_perm === 1) {
          this.delete()
          // }
          break
        case 5:
          // if (this.permission.delete_perm === 1) {
          this.hide()
          // }
          break
        case 7:
          // if (this.permission.delete_perm === 1) {
          this.unhide()
          // }
          break
      }
    },
    searchEnter() {
      this.getDataFromApi()
    },
    initDropdown() {
      this.companyDropdown()
      this.dropdownUnit()
      this.dropdownGlobalUnit()
      this.dropdownDocStatus()
      this.dropdownDocCategory()
    },
    companyDropdown() {
      this.dropdownPlant()
      // .then(res => {
      //   if (res.status_code === '00') {
      //     this.company = res.data
      //   }
      //   return null
      // })
      // .catch(err => {
      //   return err
      // })
    },
    async dropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.paramAPI.company_id}}]`
        )
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.department = res.data.data)
          }
          return (this.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.department = [])
        })
    },
    getDataFromApi() {
      this.loading = true
      this.itemEdit = null
      this.actionValue = 0
      this.selectedData = []
      this.initDataTable().then(data => {
        this.result = data.data
        this.totalData = data.total_record
        this.loading = false
      })
    },
    initDataTable() {
      return new Promise(resolve => {
        axios
          .get(
            `${this.eSanqua}archive_document/list?is_archived=${
              this.paramAPI.isArchive
            }&keyword=${this.paramAPI.keyword}&doc_status_id=${
              this.paramAPI.status
            }&order_by=${this.paramAPI.sortBy}&order_type=${
              this.paramAPI.sortType
            }&document_type_id=${this.paramAPI.doc_type}&plant_id=${
              this.paramAPI.company_id
            }&pic_department_id=${this.paramAPI.dept_id}&offset=${this.paramAPI
              .offset * this.paramAPI.limit}&limit=${
              this.paramAPI.limit
            }&category_id=${this.paramAPI.category_id}`
          )
          .then(res => {
            // this.result = res.data.data
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    categoryChange(val) {
      console.log(val)
      if (val !== null) {
        this.paramAPI.category_id = val
      } else {
        this.paramAPI.category_id = ''
      }
      this.getDataFromApi()
    },
    updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      this.getDataFromApi()
    },
    updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      this.getDataFromApi()
    },
    convertDate(raw) {
      if (raw !== null) {
        return raw.replace(/-/g, '/')
      } else {
        return raw
      }
    },
    status(raw) {
      if (raw.id === 1) {
        return 'green'
      }
      if (raw.id === 2 || raw.id === 6) {
        return 'orange'
      }
      if (raw.id === 3 || raw.id === 4 || raw.id === 5 || raw.id === 4) {
        return 'red'
      }
    },
    singkat(raw) {
      let name = ''
      switch (raw.id) {
        case 1:
          name = 'TMP'
          break
        case 2:
          name = 'IMP'
          break
        case 3:
          name = 'GIT'
          break
        case 4:
          name = 'KAJ'
          break
        case 5:
          name = 'WIM'
          break
        case 6:
          name = 'SMI'
          break
      }
      return name
    },
    closePdf() {
      this.dialog = false
      this.isPdfOpen = false
      this.showPdf = false
    },
    addArchive() {
      var app = this.getUserProfile.level
      this.initDropdown(app)
      setTimeout(() => {
        document.getElementById('archiveAdd').click()
      }, 500)
    },
    importArchive() {
      // setTimeout(() => {
      document.getElementById('archiveImport').click()
      // }, 500)
    },
    rowClick(pItem) {
      axios
        .get(`${this.eSanqua}archive_document/detail/${pItem.id}`)
        .then(res => {
          this.detail = res.data.data
          document.getElementById('archivedetail').click()
        })
        .catch(err => {
          console.log(err)
        })
    },
    viewPDF(pItem) {
      if (!this.isPdfOpen) {
        this.dialog = true
        this.isPdfOpen = true
        this.selectedPdf = pItem.file
        this.countDownTimer()
      }
    },
    delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(res => {
        if (res.isConfirmed) {
          axios
            .delete(
              `${this.eSanqua}archive_document/delete/${this.selectedData[0].id}`
            )
            .then(res => {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    hide() {
      this.showMsgDialog('question', 'Hide Now', true).then(res => {
        if (res.isConfirmed) {
          axios
            .put(
              `${this.eSanqua}archive_document/archive/${this.selectedData[0].id}`,
              { is_delete: 1 }
            )
            .then(res => {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    unhide() {
      this.showMsgDialog('question', 'Unhide Now', true).then(res => {
        if (res.isConfirmed) {
          axios
            .put(
              `${this.eSanqua}archive_document/archive/${this.selectedData[0].id}`,
              { is_delete: 0 }
            )
            .then(res => {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    clear() {
      this.detail = null
    },
    downloadPdf(pValue) {
      const extension = pValue.file.slice(pValue.file.lastIndexOf('.'))
      axios({
        url: `${this.download}esanqua/archive_document/sop/${pValue.file}?access_type=view`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${pValue.doc_no + '-' + pValue.name + extension}`
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    },
    countDownTimer() {
      if (this.counter < this.timer) {
        setTimeout(() => {
          this.counter += 1
          this.value += 10
          this.countDownTimer()
        }, 1)
      } else {
        this.showPdf = true
        this.value = 0
        this.counter = 0
      }
    },
    async openHandler(pdfApp) {
      this.info = []
      const info = await pdfApp.pdfDocument
        .getMetadata()
        .catch(console.error.bind(console))

      if (!info) return
      const props = Object.keys(info.info)
      props.forEach(prop => {
        const obj = {
          name: prop,
          value: info.info[prop]
        }
        this.info.push(obj)
      })
    },
    pagesRendered(pdfApp) {
      setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = 'page-fit'))
    },
    disableItem(item) {
      if (this.paramAPI.isArchive === '1') {
        return item.id === 5
      } else {
        return item.id === 7
      }
    }
  }
}
</script>
<style lang="scss">
#requestList {
  margin-bottom: 10px;
  .request-list-div {
    padding: 20px 15px 0;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .request-list-toolbar-1 {
      .request-list-toolbar-div {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .request-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-add-new-btn {
  padding: 1px 4px;
  box-shadow: 1px 1px 1px rgb(134, 132, 132);
  border: 1px solid #cacad4;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 2px 7px rgb(134, 132, 132);
    color: blue;
    text-decoration: underline;
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 7px;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}
@media (max-width: 768px) {
  #requestList {
    .request-list-div {
      .request-list-toolbar-1 {
        .request-list-toolbar-div {
          display: none;
        }
      }
      .request-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
